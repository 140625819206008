import React from "react"
import BgImg from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const getData = graphql`
  {
    graphic: file(relativePath: { eq: "cah-background-pattern.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const BackgroundPatternOne = () => {
  const data = useStaticQuery(getData)
  const imageData = data.graphic.childImageSharp.fluid
  return <BackgroundPatternOneStyled Tag="div" fluid={imageData} />
}

const BackgroundPatternOneStyled = styled(BgImg)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat !important;
  background-size: cover !important;
`

export default BackgroundPatternOne
